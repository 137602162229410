import {
  CREATE,
  DELETE,
  FETCH,
  FETCH_LIST,
  RESET,
  UPDATE,
} from "./createReducers";
import { get, post, put } from "./api";
import { ApiReducerState, LoadingState } from "./createReducers";

//**** resourceName에 따라 reducer에서 별도로 관리 됨. *****
// action(create,fetch 등) / resourceName 형태로 처리 됨. (reducer에서)

// const store = CustomStore;
// const state = store.getState();

// const isLoading = (action: string, category: any): boolean => {
//   const categoryState: ApiReducerState = state[
//     category
//   ] as unknown as ApiReducerState;
//   // console.log("categoryState: ", categoryState);
//   const loadingState: boolean = categoryState.loadingState[
//     `${action}/${category}`
//   ] as boolean;
//   // console.log("loadingState: ", loadingState);
//   // console.log("state: ", `${action}/${category}`);

//   return loadingState;
// };

const createActions = (
  resourceName: string
  /*key = "id"*/
) => ({
  custom: (typeName: string, url: string, params: any, meta = {}) => ({
    type: typeName,
    params: params,
    /*
     *
     *  함수는 실제 비동기 작업을 처리하고,
     * 작업이 성공하면 redux-pack 미들웨어가 자동으로
     *  관련된 액션 상태를 업데이트합니다. redux-pack 미들웨어는
     * 액션 객체의 promise 속성을 감지하고,
     * 해당 비동기 작업의 성공 여부에 따라 start, success, failure 콜백 함수를
     *  자동으로 호출하여 액션 상태를 업데이트합니다.
     *
     */
    //미들웨어에 의해 자동으로 처리됨. (promise) => success, failure 등
    promise: get(url, params),

    //meta 데이터는 리듀서에서 사용하려면 직접 구현하여 사용해야함.
    meta: {
      ...meta,
      // key,
      resourceName,
    },

    // success: handleComplexLogic, // 복잡한 로직 처리 함수 연결
  }),

  collection: (params: any, meta = {}) => ({
    type: FETCH_LIST,
    params: params,
    /*
     *
     *  함수는 실제 비동기 작업을 처리하고,
     * 작업이 성공하면 redux-pack 미들웨어가 자동으로
     *  관련된 액션 상태를 업데이트합니다. redux-pack 미들웨어는
     * 액션 객체의 promise 속성을 감지하고,
     * 해당 비동기 작업의 성공 여부에 따라 start, success, failure 콜백 함수를
     *  자동으로 호출하여 액션 상태를 업데이트합니다.
     *
     */
    //미들웨어에 의해 자동으로 처리됨. (promise) => success, failure 등
    promise: params.url ? get(params.url, params) : get(resourceName, params),

    //meta 데이터는 리듀서에서 사용하려면 직접 구현하여 사용해야함.
    meta: {
      ...meta,
      // key,
      resourceName,
    },

    // success: handleComplexLogic, // 복잡한 로직 처리 함수 연결
  }),

  item: (id: number, params: any, meta = {}) => ({
    type: FETCH,
    params: params,
    promise: params.url
      ? get(params.url, params)
      : get(`${resourceName}/${id}`, params),
    meta: {
      ...meta,
      // key,
      resourceName,
    },
  }),

  // tempItem: (type: string, url: string, params = {}, meta = {}) => ({
  //   type: type,
  //   promise: get(url, params),
  //   meta: {
  //     ...meta,
  //     // key,
  //     resourceName,
  //   },
  // }),

  create: (data: any, params: any, meta = {}) => ({
    type: CREATE,
    params: params,

    promise: params.url
      ? post(params.url, data, params)
      : post(resourceName, data, params),

    meta: {
      ...meta,
      // key,
      resourceName,
    },
  }),

  // update: (id: number, data: any, params = {}, meta = {}) => ({
  //   type: UPDATE,
  //   promise: put(`${resourceName}/${id}`, data, params),
  //   meta: {
  //     ...meta,
  //     // key,
  //     resourceName,
  //   },
  // }),
  update: (data: any, params = {}, meta = {}) => ({
    type: UPDATE,
    params: params,
    promise: put(`${resourceName}`, data, params),
    meta: {
      ...meta,
      // key,
      resourceName,
    },
  }),

  remove: (id: number, params = {}, meta = {}) => ({
    type: DELETE + "_" + resourceName,
    params: params,
    promise: get(`${resourceName}/delete/${id}`, params),
    meta: {
      ...meta,
      // key,
      resourceName,
    },
  }),

  reset: () => ({
    type: RESET,
    meta: { resourceName },
  }),
});

export default createActions;
