import { checkAllCart, isLoginUser, isOrderPossible } from "@/Utils/Utils";
import { get } from "@/redux/module/api";
import { PropsFromRedux, connector } from "@/redux/module/cart/CartProps";
import { CartNodePropsType } from "@/types/PropsTypes";
import { Troubleshoot } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { Component } from "react";

interface Props {
  amount: number;
  taxFreeAmount: number;

  contents: string;
  isCartOrder: number;
  preorder: boolean;

  oneProductData?: CartNodePropsType[];

  width?: string;
  style?: any;
}

type CombinedProps = PropsFromRedux & Props;

class OrderButton extends Component<
  CombinedProps,
  { isLogin: boolean; isClicked: boolean }
> {
  constructor(props: CombinedProps) {
    super(props);

    isLoginUser().then((res: any) => {
      this.setState({
        isLogin: res,
      });
    });

    this.state = {
      isLogin: false,
      isClicked: false,
    };
  }

  onLoginOkay = async (
    orderData: any,
    amount: number,
    preorder: boolean,
    taxFreeAmount: number
  ) => {
    await this.props.addOrderCartData(orderData);

    const res = await get("order/customerKey", {});
    if (res.status === 200) {
      const data = await res.data;

      this.props.setOrderData(
        res.data.resData,
        res.data.customerEmail,
        amount,
        taxFreeAmount,
        preorder,
        res.data.customerName,
        this.props.isCartOrder
      );

      location.href = "/order";
    }
  };
  setButtonClick = (isClicked: boolean) => {
    this.setState({
      isClicked: isClicked,
    });
  };
  render() {
    const {
      resData,

      amount, //props로 받는 데이터
      taxFreeAmount,

      contents,
      isCartOrder,
      oneProductData,
      preorder,

      // taxFreePrice, //state에 관리되는 데이터
    } = this.props;
    const orderData = isCartOrder ? resData : oneProductData;

    return (
      <Button
        variant="contained"
        className={
          preorder
            ? "commonFullBtn MuiButton-root"
            : //  "commonFullPreorderBtn MuiButton-root"
              "commonFullBtn MuiButton-root"
        }
        style={{
          width: this.props.width ? this.props.width : "100%",
          ...this.props.style,
        }}
        disabled={this.state.isClicked}
        onClick={async (e) => {
          this.setButtonClick(true);

          if (this.state.isLogin) {
            if (!this.props.isCartOrder) {
              const result = await isOrderPossible(
                orderData[0].productId,
                orderData[0].quantity
              );

              this.setButtonClick(false);
              if (result === false) {
                return;
              }
            } else {
              const result = await checkAllCart();

              this.setButtonClick(false);
              if (result !== "") {
                alert(result);
                return;
              }
            }
            this.onLoginOkay(orderData, amount, preorder, taxFreeAmount);
          } else {
            this.setButtonClick(false);
            alert("로그인이 필요합니다.");
            location.href = "/login";
          }
        }}
      >
        <span
          style={{
            // fontSize: "4",
            color: "#ffffff",
          }}
        >
          {contents}
        </span>
      </Button>
    );
  }
}

export default connector(OrderButton);
