import { Button, ButtonGroup } from "@mui/material";
import React, { Component } from "react";

class StockSelectBtn extends Component<{
  onClick: any;
  onChangeNumber?: any;
  stockValue: any;
}> {
  render() {
    const { onClick, onChangeNumber, stockValue } = this.props;
    return (
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button onClick={(e: any) => onClick("minus")}>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            -
          </span>
        </Button>
        <Button
          onChange={(e: any) =>
            onChangeNumber ? onChangeNumber(e.target.value) : null
          }
        >
          <span
            style={{
              fontSize: "15px",
            }}
          >
            {stockValue}
          </span>
        </Button>
        <Button onClick={(e: any) => onClick("plus")}>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            +
          </span>
        </Button>
      </ButtonGroup>
    );
  }
}

export default StockSelectBtn;
