import { get } from "../api";
import createActions from "../createActions";

//액션 생성
const { collection, create, remove, item } = createActions("product");
const cartAction = createActions("cart");
const PAGE_SIZE = 999;
// const PAGE_SIZE = 10;

export function fetchSelectedProduct(id: number, params: {}) {
  const meta = {
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return {
    type: "ADD_PRODUCT_SELECTED_DATA_product",
    promise: get(`product/${id}`, params),
    payload: {},
    meta: {
      ...meta,
      // key,
      resourceName: "product",
    },
  };
}

export function fetchProduct(id: number, params: {}) {
  const meta = {
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return item(
    id,
    {
      ...params,
    },
    meta
  );
}

export function fetchListProduct(params: {}, pageNumber: number) {
  const meta = {
    pageNumber: pageNumber,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return collection(
    /** 요청 파라미터  */
    {
      ...params,
      //   pageNumber,
      //   limit: PAGE_SIZE,
    },
    meta
  );
}

export function createProduct(data: any, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return create(data, params, meta);
}

export function deleteProduct(id: number, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return remove(id, params, meta);
}

export function fetchCart(id: number, params: {}) {
  const meta = {
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return cartAction.item(
    id,
    {
      ...params,
    },
    meta
  );
}

export function fetchListCart(params: {}, pageNumber: number) {
  const meta = {
    pageNumber: pageNumber,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return cartAction.collection(
    /** 요청 파라미터  */
    {
      ...params,
      //   pageNumber,
      //   limit: PAGE_SIZE,
    },
    meta
  );
}

export function createCart(data: any, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return cartAction.create(data, params, meta);
}

export function deleteCart(id: number, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return cartAction.remove(id, params, meta);
}

export function fetchQna(id: number, params: {}) {
  const meta = {
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return item(
    id,
    {
      ...params,
    },
    meta
  );
}

export function fetchListQna(params: {}, pageNumber: number) {
  const meta = {
    pageNumber: pageNumber,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return collection(
    /** 요청 파라미터  */
    {
      ...params,
      //   pageNumber,
      //   limit: PAGE_SIZE,
    },
    meta
  );
}
export function createQna(data: any, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return create(data, params, meta);
}
