import { connect, ConnectedProps } from "react-redux";

import {
  createCart,
  fetchListCart,
  deleteCart,
  fetchCart,
  addOrderCartData,
  setOrderData,
  setOrderDeliveryAddr,
} from "./cartAction";
import { withRouter } from "next/router";
import { DeliveryAddr } from "@/types/MemberTypes";

//맵핑 함수

export const mapStateToProps = (state: any) => {
  return {
    resData: state.cart.resData ? state.cart.resData : null,

    orderCartData: state.cart.orderCartData ? state.cart.orderCartData : null,
    // customerId: state.cart.customerId ? state.cart.customerId : null,

    customerKey: state.cart.customerKey ? state.cart.customerKey : null,
    customerEmail: state.cart.customerEmail ? state.cart.customerEmail : null,

    paymentPrice: state.cart.paymentPrice ? state.cart.paymentPrice : null,
    taxFreePrice: state.cart.taxFreePrice ? state.cart.taxFreePrice : 0,

    isPreorder: state.cart.isPreorder ? state.cart.isPreorder : null,
    customerName: state.cart.customerName ? state.cart.customerName : null,
    isCartOrderData: state.cart.isCartOrderData
      ? state.cart.isCartOrderData
      : 0,

    deliveryAddr: state.cart.deliveryAddr ? state.cart.deliveryAddr : null,

    loadingState: state.cart.loadingState ? state.cart.loadingState : null,
    errorState: state.cart.errorState ? state.cart.errorState : null,
    pagination: state.cart.pagination ? state.cart.pagination : null,
  };
};
export const mapDispatchToProps = (dispatch: any) => {
  // console.log(fetchListProduct);
  return {
    fetchListCart: (params: any, pageNumber: any) =>
      dispatch(fetchListCart(params, pageNumber)),

    fetchCart: (id: number, params: any) => dispatch(fetchCart(id, params)),

    createCart: (data: any, params: any, meta: any) =>
      dispatch(createCart(data, params, meta)),

    deleteCart: (id: number, params: any, meta: any) =>
      dispatch(deleteCart(id, params, meta)),

    addOrderCartData: (data: any) => dispatch(addOrderCartData(data)),

    setOrderData: (
      customerKey: string,
      customerEmail: string,
      paymentPrice: number,
      taxFreePrice: number,
      isPreorder: boolean,
      customerName: string,

      isCartOrderData: number
    ) =>
      dispatch(
        setOrderData(
          customerKey,
          customerEmail,
          paymentPrice,
          taxFreePrice,
          isPreorder,
          customerName,

          isCartOrderData
        )
      ),

    setOrderDeliveryAddr: (deliveryAddr: DeliveryAddr) =>
      dispatch(setOrderDeliveryAddr(deliveryAddr)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
