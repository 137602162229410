import { getRGBAValue } from "@/Utils/Utils";
import { Box } from "@mui/material";
import React, { Component } from "react";

interface PropsType {
  title: any;
  right?: any;
  backgroundColor?: string;
  type?: string;
  alpha?: number;
}

interface StateType {}

class NormalBox extends Component<PropsType, StateType> {
  render() {
    const { backgroundColor, type, right, alpha } = this.props;
    //right 이 없으면 단독 사용
    return (
      <div
        style={{
          display: "block",
          // width: right ? "auto" : "100%",
          height: "auto",
          marginTop: type === "sub" ? "7px" : "7px",
          marginBottom: "0",
        }}
      >
        <Box
          className={type === "sub" ? "elementBoxSub" : "elementBox"}
          sx={{
            backgroundColor: backgroundColor
              ? alpha !== undefined
                ? getRGBAValue(backgroundColor, alpha)
                : backgroundColor
              : alpha !== undefined
              ? getRGBAValue("#f9f9f9", alpha)
              : "#f9f9f9",
            border: 1,
            // borderRadius: 1,
            borderColor: "divider",
            height: "auto",
          }}
        >
          <div
            className={type === "sub" ? "normalSpanSub" : "normalSpan"}
            style={{
              display: "inline-block",
              width: "100%",
              padding: 0,
            }}
          >
            <div
              style={{
                width: right ? "auto" : "100%",
                display: "inline-block",
                // margin: "10px",
                // padding: right ? "10px" : "0",
                padding: right ? "10px" : "0px",
                //세로 중앙 정렬
                verticalAlign: "middle",
                overflow: "hidden",
              }}
            >
              {this.props.title}
            </div>
            {right && (
              <div
                style={{
                  //우측 정렬
                  float: "right",
                  textAlign: "right",
                  padding: "10px",
                  // width: "40%",
                }}
              >
                {this.props.right}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
}

export default NormalBox;
