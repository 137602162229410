import { getRGBAValue } from "@/Utils/Utils";
import React, { Component } from "react";
import { Box } from "@mui/material";

class Label extends Component<{
  content: any;
  color: string;
  marginLeft?: string;
  border?: number;
  borderColor?: string;
  fontSize?: string;
}> {
  render() {
    return (
      <Box
        style={{
          display: "inline",
          width: "auto",
          color: "#ffffff",
          fontSize: this.props.fontSize ? this.props.fontSize : "0.75rem",
          marginLeft: this.props.marginLeft ? this.props.marginLeft : "5px",
          padding: this.props.border ? "2px" : "4px",
          backgroundColor: getRGBAValue(this.props.color, 1),
          borderRadius: this.props.border ? "4px" : "2px",
        }}
        sx={{
          border: this.props.border ? this.props.border : 0,
          borderColor: this.props.borderColor ? this.props.borderColor : "",
        }}
      >
        {this.props.content}
      </Box>
    );
  }
}

export default Label;
