import { orangePrimary } from "@/styles/theme";
import React, { Component } from "react";

class PreorderWarning extends Component {
  render() {
    return (
      <div
        style={{
          marginBottom: "10px",
          backgroundColor: orangePrimary,
          color: "#ffffff",
          padding: "3px",
          borderRadius: "5px",
          paddingLeft: "5px",
          fontSize: "0.8rem",
        }}
      >
        {/* 예약 상품은 주문 후 취소가 불가합니다. 신중히 주문 해주세요. */}예약
        상품으로, 마감일 이후 취소가 불가합니다. 신중히 주문 해주세요.
      </div>
    );
  }
}

export default PreorderWarning;
