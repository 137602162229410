import { connect, ConnectedProps } from "react-redux";

import {
  createProduct,
  fetchListProduct,
  deleteProduct,
  fetchProduct,
  createCart,
  fetchListCart,
  deleteCart,
  fetchCart,
  fetchSelectedProduct,
  fetchQna,
  fetchListQna,
  createQna,
} from "./productAction";
import { withRouter } from "next/router";
import { ApiReducerState } from "../createReducers";

//맵핑 함수

export const mapStateToProps = (state: any) => {
  const stateData: ApiReducerState = state.product as ApiReducerState;
  const stateCartData: any = state.cart as any;

  return {
    // resData: stateData.resData ? stateData.resData : null,

    // qnaData: stateData.qnaData ? stateData.qnaData : null,

    selectedProduct: stateData.selectedProduct
      ? stateData.selectedProduct
      : null,

    loadingState: stateData.loadingState ? stateData.loadingState : null,
    errorState: stateData.errorState ? stateData.errorState : null,
    pagination: stateData.pagination ? stateData.pagination : null,

    cartResData: stateCartData.cartResData ? stateCartData.cartResData : null,

    cartLoadingState: stateCartData.cartLoadingState
      ? stateCartData.cartLoadingState
      : null,
    cartErrorState: stateCartData.cartErrorState
      ? stateCartData.cartErrorState
      : null,
    cartPagination: stateCartData.cartPagination
      ? stateCartData.cartPagination
      : null,
  };
};
export const mapDispatchToProps = (dispatch: any) => {
  // console.log(fetchListProduct);
  return {
    fetchListProduct: (params: any, pageNumber: any) =>
      dispatch(fetchListProduct(params, pageNumber)),

    fetchProduct: (id: number, params: any) =>
      dispatch(fetchProduct(id, params)),

    fetchSelectedProduct: (id: number, params: any) =>
      dispatch(fetchSelectedProduct(id, params)),

    createProduct: (data: any, params: any, meta: any) =>
      dispatch(createProduct(data, params, meta)),

    deleteProduct: (id: number, params: any, meta: any) =>
      dispatch(deleteProduct(id, params, meta)),

    fetchListCart: (params: any, pageNumber: any) =>
      dispatch(fetchListCart(params, pageNumber)),

    fetchCart: (id: number, params: any) => dispatch(fetchCart(id, params)),

    createCart: (data: any, params: any, meta: any) =>
      dispatch(createCart(data, params, meta)),

    deleteCart: (id: number, params: any, meta: any) =>
      dispatch(deleteCart(id, params, meta)),

    fetchQna: (id: number, params: any) => dispatch(fetchQna(id, params)),
    fetchListQna: (params: any, pageNumber: any) =>
      dispatch(fetchListQna(params, pageNumber)),

    createQna: (data: any, params: any, meta: any) =>
      dispatch(createQna(data, params, meta)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
