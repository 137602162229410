import { DeliveryAddr } from "@/types/MemberTypes";
import createActions from "../createActions";

//액션 생성
const { collection, create, remove, item } = createActions("cart");
const PAGE_SIZE = 999;
// const PAGE_SIZE = 10;

//주문서에서 출력되는 상품 리스트 카트 정보
export function addOrderCartData(data: any) {
  return {
    type: "ADD_ORDER_CART_DATA_cart",
    payload: data,
    meta: {
      resourceName: "cart",
    },
  };
}

export function setOrderDeliveryAddr(deliveryAddr: DeliveryAddr) {
  return {
    type: "ADD_ORDER_DELIVERY_DATA_cart",
    deliveryAddr: deliveryAddr,
    meta: {
      resourceName: "cart",
    },
  };
}

//주문서에서 사용되는 주문관련 정보
export function setOrderData(
  customerKey: string,
  customerEmail: string,
  paymentPrice: number,
  taxFreePrice: number,
  isPreorder: boolean,
  customerName: string,

  isCartOrderData: number
) {
  return {
    type: "ADD_ORDER_DATA_cart",
    meta: {
      resourceName: "cart",
    },

    customerKey: customerKey,
    customerEmail: customerEmail,
    paymentPrice: paymentPrice,
    taxFreePrice: taxFreePrice,
    isPreorder: isPreorder,
    customerName: customerName,

    isCartOrderData: isCartOrderData,
  };
}

export function fetchCart(id: number, params: {}) {
  const meta = {
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return item(
    id,
    {
      ...params,
    },
    meta
  );
}

export function fetchListCart(params: {}, pageNumber: number) {
  const meta = {
    pageNumber: pageNumber,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
    // key
  };

  return collection(
    /** 요청 파라미터  */
    {
      ...params,
      //   pageNumber,
      //   limit: PAGE_SIZE,
    },
    meta
  );
}

export function createCart(data: any, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "성공적으로 요청하였습니다.",
    },
  };

  return create(data, params, meta);
}

export function deleteCart(id: number, params: any, onComplete: any) {
  const meta = {
    onSuccess: onComplete,
    notification: {
      error: "에러가 발생했습니다.",
      success: "삭제되었습니다.",
    },
  };

  return remove(id, params, meta);
}
